<template>
<div class="p-3">
  <b-alert variant="warning" show>
    Notice: ?roomId=&lt;&gt;&uid=&lt;&gt;<br/>
    Or: ?room={users:[&lt;&gt;]}&uid=&lt;&gt;<br/>
  </b-alert>
  <h3>Test Room</h3>
  Room Id: {{ roomId }}<br/>
  <debug-obj label="Room" :objData="room"/>
  <div v-if="room">
    Select user:
    <div v-for="u in room.users" v-bind:key="u">
      <input type="radio" :id="u" :value="u" v-model="userId"/>&nbsp;
      <label :for="u">{{ u }}</label>
    </div>
  </div>
  <debug-obj label="Users" :objData="users"/>
  <hr/>
  <webrtc
    ref="webrtc"
    v-if="room"
    :room="room"
    :room-users="room.users"
    :user="{id:userId, name:'Test Room User #' + userId}"
    :user-config="{startMuted:true}"
    @usersChange="webRTCUsersChanged"
  />
  <div class="form-inline sub-mr-2 sub-mt-2 mb-2" v-if="$refs.webrtc">
    <div>Controls</div>
    <button class="btn btn-primary" @click="$refs.webrtc.setMuted(true)" v-if="!$refs.webrtc.muted">Mute</button>
    <button class="btn btn-primary" @click="$refs.webrtc.setMuted(false)" v-else>Unmute</button>
  </div>
  <video-layout
    layout="side-by-side"
    :users="users"/>
</div>
</template>

<script>
import { db } from '@/services/db';
import { getLog } from "@/services/log";
import DebugObj from '@/components/debugObj.vue';
import Webrtc2 from '@/components/webrtc2.vue';
import roomRTC from '@/components/roomRTC.vue';
import VideoLayout from '@/components/videoLayout.vue';
let log = getLog('test-room');

let useAgora = true;

export default {
  name: 'app',
  components: {
    DebugObj,
    Webrtc : useAgora ? roomRTC : Webrtc2,
    VideoLayout,
  },
  data() {
    return {
      roomId: "",
      room: null,
      userId: "",
      users: null,
    };
  },
  computed: {
  },
  mounted() {
    this.$debug.isOn = true;

    if (this.$route.query.roomId)
      this.roomId = this.$route.query.roomId;

    // room
    let roomParams = {users:["user1", "user2"]};
    if (this.$route.query.room) {
      log.log("room=", this.$route.query.room);
      roomParams = JSON.parse(this.$route.query.room);
    }
    this.room = Object.assign({id:"testroomid",video:true}, roomParams);

    // user id
    this.userId = this.$route.query.uid
    log.log(`Mounted room:${this.roomId} user:${this.userId}`);
  },
  watch: {
    roomId() {
      this.loadRoom(this.roomId);
    },
  },
  methods: {
    // Room
    async loadRoom(roomId) {
      log.log("loadRoom");
      return this.$bind("room", db.collection("LiveRooms").doc(roomId)).then((room) => {
        if (!room)
          log.error("Room does not exist");
        log.log("Room loaded", room);
      });
    },
    webRTCUsersChanged(users) {
      this.users = users;
    }
  }
}
</script>

<style scoped>

.bg {
  background-color: powderblue;
}

</style>
