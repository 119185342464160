<template>
<div>
  <!-- Permission issue -->
  <b-alert variant="danger mt-3 mb-3" :show="permissionsIssue">
    {{ $app.productName }} is unable to access your {{ config.video ? 'camera and' : '' }} microphone.<br/>
    1. Please turn on and authorize your {{ config.video ? 'camera and' : '' }} microphone.<br/>
    <img class="m-3" style="max-width: 300px; border: solid 1px #000;" src="@/assets/permissions_after.png"/><br/>
    2. Check your video settings below and save.<br/>
    When done, press Restart <button class="btn btn-primary" @click="createStream()">Restart</button>
  </b-alert>
  <!-- Failure due to no result -->
  <b-alert variant="danger mt-3 mb-3" :show="detectionIssue">
    {{ $app.productName }} is unable to access your {{ config.video ? 'camera and' : '' }} microphone.<br/>
    Please close other apps using your {{ config.video ? 'camera and' : '' }} microphone,
    and press Restart <button class="btn btn-primary" @click="createStream()">Restart</button>
  </b-alert>
  <!-- Debug -->
  <div v-if="$debug.isOn" class="alert-info mb-2">
    UserMedia
    <debug-obj label="user" :objData="user" folded/>
    <debug-obj label="config" :objData="config"/>
    <debug-obj label="localUser" :objData="localUser"/>
  </div>
</div>
</template>

<script>
import { getLog, setDbLog } from "@/services/log";
let log = getLog('user-media');
import { stopStream, getStreamInfo, getUserMedia } from '@/services/mediautils';
import { Mutex } from "async-mutex";

export default {
  props: {
    user: {
      type: Object,
      default: () => { return {}; }
    },
    config: {
      type: Object,
      default: () => { 
        return {
          startMuted: false,
          noUserMedia: false,
          dbLogChannel: null,
          video: false,
          settings: false,
          invisible: false,
        };
      }
    }
  },
  data() {
    return {
      mutex: new Mutex(),

      permissionsIssue: false,
      detectionIssue: false,

      localUser: null,
      muted: false,

      stream: null,
      screenStream: null,

      onDeviceChangeHandler: null,
    };
  },
  watch: {
    localUser() {
      this.$emit('user-update', this.localUser);
    }
  },
  mounted() {
    this.init();
    this.onDeviceChangeHandler = this.onDeviceChange.bind(this);
    navigator.mediaDevices.addEventListener("devicechange", this.onDeviceChangeHandler);
  },
  beforeDestroy() {
    navigator.mediaDevices.removeEventListener("devicechange", this.onDeviceChangeHandler);
    if (this.stream) this.destroyStream();
    if (this.screenStream) this.stopScreenShare();
  },
  methods: {
    init() {
      if (this.config.dbLogChannel)
        setDbLog(log, this.config.dbLogChannel, `${this.user.id}_um`);
      this.setupCurrentUser();
      this.createStream();
    },
    onDeviceChange() {
      log.log("onDeviceChange");
    },
    // User object
    setupCurrentUser() {
      log.log("setupCurrentUser");
      this.muted = this.config.startMuted || false;
      this.userSetMerge({
        id: this.user.id || "N/A",
        name: this.user.name || "N/A",
        local: true,
        muted: this.muted,
        invisible: this.config.startInvisible,
      });
    },
    userSetMerge(data) {
      log.log("userSetMerge", data);
      this.localUser = Object.assign({}, Object.assign(this.localUser || {}, data));
    },
    setMuted(muted) {
      this.muted = muted;
      log.log("Setting audio muted:", this.muted);
      this.userSetMerge({muted});
      if (this.stream)
        this.stream.getAudioTracks()[0].enabled = !this.muted;
    },
    // Main stream
    async createStream(settings) {
      if (this.config.noUserMedia) {
        log.log(`createStream noUserMedia`);
        return undefined;
      }
      let release = await this.mutex.acquire();
      this.permissionsIssue = this.detectionIssue = false;
      log.log("createStream, config=", this.config, ", settings=", settings, ", config.settings=", this.config.settings);
      let data = {};
      try {
        settings = settings || this.config.settings || {};
        let constrains = {
          video: settings.video !== undefined ? this.config.video && settings.video : this.config.video,
          audio: true,
          definition: settings.definition || (this.$debug.lowDef ? 'low' : 'auto'),
        };
        log.log("contraints=", constrains);
        // Remove existing stream helps with detection tests
        if (this.stream) {
          stopStream(this.stream);
          this.stream = null;
        }
        let stream = await getUserMedia(constrains, settings);
        log.log("streamInfo=", getStreamInfo(stream));
        this.stream = stream;
      } catch (error) {
        log.log("getUserMedia error", error, error.name); 
        if (error.name == 'NotAllowedError') {
          this.permissionsIssue = true;
          return;
        } else {
          this.detectionIssue = true;
          return;
        }
      } finally {
        release();
      }
      data.image = !this.stream.getVideoTracks().length ? this.user.picture0 || "https://ui-avatars.com/api/?size=512&name=😧" : null;
      data.stream = this.stream;
      this.userSetMerge(data);
      if (this.config.startMuted || this.muted)
        this.setMuted(true);
      return this.stream;
    },
    destroyStream() {
      log.log("destroyStream");
      // Clear my media
      if (this.stream) {
        stopStream(this.stream);
        this.stream = null;
        this.userSetMerge({stream: null});
      }
    },
    // Screen Sharing
    async shareScreen(constraints) {
      try {
        if (this.screenStream)
          stopStream(this.screenStream);
        let screenStream = await navigator.mediaDevices.getDisplayMedia(constraints || {
          video: {
            cursor: "always"
          },
          audio: true,
        });
        this.screenStream = screenStream;
        log.log("shareScreen stream", screenStream, getStreamInfo(screenStream));
        this.userSetMerge({screenStream});
        screenStream.oninactive = () => {
          log.log("shareScreen stream inactive");
          this.userSetMerge({screenStream: null});
        };
      } catch (err) {
        log.log("shareScreen error=", err);
      }
      return this.screenStream;
    },
    stopScreenShare() {
      if (!this.screenStream)
        return;
      stopStream(this.screenStream);
      this.screenStream = null;
      this.userSetMerge({screenStream: null});
    },
  }
}
</script>

<style>

</style>