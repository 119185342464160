<template>
  <div>
    <b-alert variant="danger mt-1 mb-1" :show="user && room && !roomUsers.includes(user.id)">
      This userId {{ user.id }} is not assigned to this room {{ room.id }}.
    </b-alert>

    <b-alert variant="warning" :show="networkIssue != null">
      You or the other party are currently experiencing network issues.<br/>
      If you are not on wifi, please switch to wifi.<br/>
      If you are on wifi, please connect to internet using your phone's hotspot.<br/>
      <p class="code">
        {{ networkIssue }}
      </p>
      <button class="btn btn-primary" @click="networkIssue = null;">Close</button>
    </b-alert>

    <b-alert variant="danger mt-3 mb-3" :show="deviceIssue">
      Please turn on and authorize your {{ isVideo ? 'camera and' : '' }} microphone.
      <img class="m-3" style="max-width: 300px; border: solid 1px #000;" src="@/assets/permissions_after.png"/><br/>
      And then, press Restart <button class="btn btn-primary" @click="restartRoom">Restart</button>
    </b-alert>

    <!-- Debug -->
    <debug-group label="Toggle WebRTC Debug" v-if="$debug.isOn">
      <debug-obj label="webrtc" :objData="{ inRoom, errorCount, userIndex, muted, status, stream, screenStream }"/>
      <div class="mb-2">
        <button class="btn btn-sm btn-primary mr-2" @click="setMuted(false)" v-if="muted">Unmute</button>
        <button class="btn btn-sm btn-primary mr-2" @click="setMuted(true)" v-else>Mute</button>
        <button class="btn btn-sm btn-warning mr-2" @click="clearSignaling()">Clear Signaling</button>
        <button class="btn btn-sm btn-warning mr-2" @click="removeDatabaseMessagesFromSender(user.id)">removeDatabaseMessagesFromSender</button>
      </div>
      <button class="btn btn-sm btn-danger mr-2" @click="restartRoom()">Restart Room</button>
      <button class="btn btn-sm btn-danger mr-2" @click="leaveRoom()">Leave Room</button>
      <br/>
      <b>User Media</b>
      <debug-obj label="userConfig.settings" :objData="userConfig.settings"/>
      <b>Users</b>
      <div class="user-list" v-for="(user, index) in users" v-bind:key="'user_' + index">
        [{{index}}] <button class="btn btn-sm btn-warning" @click="forceReconnect(index)" v-if="!user.local">Reco</button> {{ user | jsonstringify }}
      </div>
      <b>Peers</b>
      <div v-for="(p, index) in peers" v-bind:key="'p_' + index">
        <debug-obj label="peer" :objData="p" :filterOut="['connection', 'messages', 'outgoing', 'incoming']"/>
      </div>
    </debug-group>
</div>
</template>

<script>
import { database } from "../services/db";
import Peer from "simple-peer";
import { getBrowser, sleep, setCharAt, arraysEqual } from '../services/utils';
import { getLog, setDbLog } from "../services/log";
let log = getLog('webrtc2');
import DebugGroup from './debugGroup.vue';
import { stopStream, getStreamInfo, getUserMedia } from '@/services/mediautils';

require('adapterjs');

export default {
  name: 'WebRTC2',
  components: {
    DebugGroup,
  },
  props: {
    room: Object,
    user: Object,
    roomUsers: Array,
    userConfig: {
      type: Object,
      default: () => { return {} },
    },
  },
  data() {
    return {
      iOSNativeWebRTC: false,

      // Configuration
      showReconnect: false,
      isVideo: false,

      // User items
      inRoom:false,
      userIndex: 0,
      stream: null,
      streamConstrains: null,
      screenStream: null,
      startMuted: false,
      muted: false,
      invisible: false,
      status: "",

      // Peer connections
      peers: [],
      users: [],
      rootDb: null,

      // Trouble shooting
      errorCount: 0,
      networkIssue: null,
      deviceIssue: false,
      showDebugVideo: false,

      // Settings to debug peer connection
      settings: {
        pauseSignal: false, // Breaking if on.
        disableDbClose: true,
        dontCreatePeerAfterClose: false, // Breaking if on.
        delayReconnectPostError: 3, // in seconds
      },
    }
  },  
  watch: {
    "roomUsers": {
      handler(value, old) {
        log.log("roomUsers updated", value, old);
        if (!arraysEqual(value, old))
          this.restartRoom();
      }
    },
    users(value) {
      this.$emit("usersChange", value);
    }
  },
  mounted() {
    log.log("mounted");
    let roomId = this.room ? this.room.id : "no-room-id";
    let userId = this.user ? this.user.id : null;
    setDbLog(log, roomId, `${userId}_w`);
    log.log(`Adapter browser: ${webrtcDetectedBrowser} version: ${webrtcDetectedVersion}`); // eslint-disable-line no-undef
    log.log(`Mounted room:${roomId} user:${userId}`);
    if (getBrowser() == "live-wrapper" && this.$app.useiOSNativeWebRTC)
      this.iOSNativeWebRTC = true;
    this.invisible = this.userConfig.startInvisible || false;
    this.restartRoom();
  },
  async beforeDestroy() {
    log.log("beforeDestroy");
    await this.leaveRoom();
  },
  methods: {
    async restartRoom() {
      log.log("restartRoom");
      if (!this.room || !this.user) {
        log.log("cannot start room");
        return;
      }
      this.startMuted = this.room.startMuted || this.userConfig.startMuted;
      this.isVideo = this.room.video || false;
      this.userIndex = this.roomUsers.findIndex((u) => u == this.user.id);
      this.users = Array(this.roomUsers.length).fill(null);
      for (var i = 0; i < this.users.length; ++i)
        this.userReset(i);
      this.peers = Array(this.roomUsers.length).fill(null);
      this.streams = Array(this.roomUsers.length).fill(null);
      this.status = "-".repeat(this.roomUsers.length);

      if (this.userIndex < 0) {
        this.$emit("userNotInRoom");
        return;
      }
      this.setupCurrentUser();
      if (this.iOSNativeWebRTC) {
        if (window.webkit) {
          window.actions = { webrtcNative: this };
          window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"joinRoom", roomId:this.room.id, userId:this.user.id, room:this.room});
        }
      } else {
        await this.createUserStream();
        await this.destroyPeers();
        await this.createPeers();
      }
    },
    async leaveRoom() {
      log.log("leaveRoom");
      if (this.iOSNativeWebRTC) {
        if (window.webkit) {
          window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"close"});
        }
        return;
      }
      this.destroyUserStream();
      this.destroyPeers();
    },
    // User interface (beta)
    async shareScreen() {
      try {
        let stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            cursor: "always"
          },
          audio: false,
        });
        if (this.screenStream != stream)
          stopStream(this.screenStream);
        this.screenStream = stream;
        log.log("shareScreen stream", stream, getStreamInfo(stream));
        this.userSetMerge(this.userIndex, {screenStream: stream});
        stream.oninactive = () => {
          log.log("shareScreen stream inactive");
          this.userSetMerge(this.userIndex, {screenStream: null});
          this.sendMessageCommand({stopScreenStream:{userIndex:this.userIndex}});
        };
        this.peers.forEach(p => {
          if (p && p.connection)
            p.connection.addStream(stream);
        })
      } catch (err) {
        log.log("shareScreen error=", err);
      }
    },
    stopScreenShare() {
      stopStream(this.screenStream);
      this.screenStream = null;
      this.userSetMerge(this.userIndex, {screenStream: null});
    },
    // User interface
    setMuted(muted) {
      this.muted = muted;
      this.userSetMerge(this.userIndex, {muted});
      log.log("Setting audio muted:", this.muted);
      if (this.iOSNativeWebRTC) {
        if (window.webkit) {
          window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"mute", on:this.muted});
        }
      } else {
        if (this.stream) {
          this.stream.getAudioTracks()[0].enabled = !this.muted;
          this.sendMessageExtra({ muted: this.muted });
        }
      }
      this.updateState();
    },
    userReset(index) {
      if (index < this.users.length)
        this.$set(this.users, index, {
          invisible: this.roomUsers[index].startsWith("studio-") ? true : undefined
        });
    },
    userSetMerge(index, data) {
      if (index < this.users.length)      
        this.$set(this.users, index, Object.assign(this.users[index], data));
    },
    updateState() {
      this.$emit('stateChange', { muted:this.muted, invisible:this.invisible, status:this.status });
    },
    // iOSNativeWebRTC internal
    onData(data) {
      log.log("onData", data);
      this.data = data;
      // data only comes from one other user
      let otherIndex = this.userIndex ? 0 : 1;
      if (data.user) {
        this.$set(this.users, otherIndex, Object.assign({}, data.user));
        this.$emit("userJoined", data.user);
      }
      if (data.extra)
        this.$set(this.users, otherIndex, Object.assign(this.users[otherIndex], data.extra));
    },
    onStatus(data) {
      log.log("onStatus", data);
      this.status = data.status;
      let otherIndex = this.userIndex ? 0 : 1;
      if (this.status == "disconnected" || this.status == "closed")
        this.userReset(otherIndex);
    },
    onDataStatus(data) {
      log.log("onDataStatus", data);
      this.dataStatus = data.status;
      if (this.dataStatus == "connecting" || this.dataStatus == "open") {
        let data = {
          user: {
            name: this.user.name,
            muted: this.muted,
            invisible: this.invisible,
          }
        }
        if (window.webkit) {
          window.webkit.messageHandlers.nativeWebRTC.postMessage({command:"sendData", data});
        }
      }
    },
    // Internal
    updatePeer(p, data) {
      this.$set(this.peers, p.peerIndex, Object.assign(p, data));
      // update status
      for (var i = 0; i < this.peers.length; i++) {
        let p = this.peers[i];
        let c = "0";
        if (!p.connection) {
          c = p.initiator ? 'h' : 'w';
        } else if (p.stream) {
          c = 's';
        } else if (p.screenStream) {
          c = 'S';
        }
        this.status = setCharAt(this.status, i, c);
      }
    },
    updatePeers() {
      this.peers.slice(0);
    },
    sendMessageThroughDatabase(p, senderId, receiverId, data, conserve=false) {
      log.log(`sendMessageThroughDatabase ${data.type} sender: ${senderId} receiver: ${receiverId}`);
      if (typeof data == "object")
        data = JSON.stringify(data);
      let ref = this.rootDb.push({ sender: senderId, receiver: receiverId, message: data });
      if (conserve)
        p.messages.push(ref);
      else
        ref.remove();
    },
    receiveMessageThroughDatabase(data, source) {
      if (!this.inRoom) {
        log.log(`[${this._uid}] not inRoom ignoring callback - instance not destroyed?`);
        return;
      }
      let val = data.val();
      let msg = JSON.parse(val.message);
      let receiver = val.receiver;
      let sender = val.sender;
      if (receiver != this.user.id)
        return;
      if (!this.roomUsers.includes(sender)) {
        log.error("sender is not in list of users");
        return;
      }
      let p = this.peers.find((peer) => peer.userId == sender);
      //log.log(`receiveMessageThroughDatabase ${sender} index=${p.roomUserIndex}`, msg);
      log.log(`receiveMessageThroughDatabase ${msg.type} source=${source} key=${data.key}`);
      // hello from originator
      if (msg.type == "hello") {
        if (p.initiator) {
          log.error("should not receive hello, this is the initiator!");
          this.rootDb.child(data.key).remove();
          return;
        }
        if (p.connection) {
          log.error("should not have a connection! already received hello");
          this.rootDb.child(data.key).remove();
          return;
        }
        this.createPeerConnection(p);
        this.sendMessageThroughDatabase(p, this.user.id, p.userId, {type:"ack"});
        this.rootDb.child(data.key).remove();
      // ack (receiver is ready)
      } else if (msg.type == "ack") {
        if (p.connection) {
          log.error("should not have a connection before ack!");
          this.rootDb.child(data.key).remove();
          return;
        }
        this.createPeerConnection(p);
        this.rootDb.child(data.key).remove();
      // closing (why?)
      } else if (msg.type == "close") {
        if (p.connection)
          if (!this.settings.disableDbClose) {
            p.receivedClose = true;
            this.destroyPeer(p);
            this.peers[p.peerIndex] = this.createPeer(p.roomUserIndex, p.peerIndex);
            this.rootDb.child(data.key).remove();
          }
      // webRTC signaling
      } else {
        if (!p.connection) {
          log.error("should have a connection, ignored and deleted");
          this.rootDb.child(data.key).remove();
          return;
        }
        p.incoming = JSON.stringify(msg);
        log.log(`[${p.roomUserIndex}] ${msg.type}`, msg);
        this.updatePeers();
        p.connection.signal(msg);
        this.rootDb.child(data.key).remove();
      }
    },
    async removeDatabaseMessagesFromSender(senderId) {
      let snapshot = await this.rootDb.once("value");
      let count = 0;
      snapshot.forEach(cs => {
        if (cs.val().sender == senderId) {
          cs.ref.remove();
          count += 1;
        }
      });
      log.log(`removeDatabaseMessagesFromSender deleted ${count}`);
    },
    async processAllExistingMessages() {
      log.log(`processAllExistingMessages`);
      let snapshot = await this.rootDb.once("value");
      let count = 0;
      snapshot.forEach(cs => {
        this.receiveMessageThroughDatabase(cs, "paem");
        count += 1;
      });
      log.log(`processAllExistingMessages processed ${count}`);
    },
    sendMessageExtra(extra) {
      log.log("sendMessageExtra", extra);
      for (let p of this.peers) {
        if (p && p.isConnected) {
          p.messageSent = { extra };
          p.connection.send(JSON.stringify(p.messageSent));
        }
      }
    },
    sendMessageCommand(cmd) {
      for (let p of this.peers) {
        if (p && p.isConnected) {
          p.messageSent = { cmd };
          p.connection.send(JSON.stringify(p.messageSent));
        }
      }
    },
    async forceReconnect(i) {
      log.log("Force Reconnect");
      let p = this.peers[i];
      if (p.local) {
        log.log("Cannot reconnect, this is a local user");
        return;
      }
      if (!this.settings.disableDbClose)
        this.sendMessageThroughDatabase(p, this.user.id, p.userId, { type : "close" });
      await this.destroyPeer(p);
      await this.createPeer(i);
    },
    // Peer Management
    createPeer(i) {
      log.log(`[${i}] createPeer`);
      if (this.peers[i]) {
        log.error("Creating peer before destroying it!");
        return;
      }
      if (i >= this.roomUsers.length) {
        log.error("Can't create a Peer for an unset user.", i, this.roomUsers);
        return;
      }

      // Initiator is i above userIndex, otherwise initiated by other.
      let initiator = (this.userIndex < i);

      // Peer
      let p = {
        initiator
      };
      this.userReset(i);
      p.userId = this.roomUsers[i];
      p.roomUserIndex = i;
      p.peerIndex = i;
      p.error = null;
      p.messages = [];
      if (p.initiator) {
        // if initiator
        (async () => {
          await this.removeDatabaseMessagesFromSender(this.user.id);
          this.sendMessageThroughDatabase(p, this.user.id, p.userId, {type:"hello"}, true);
        })();
      }
      else {
        (async () => {
          await this.removeDatabaseMessagesFromSender(this.user.id);
          log.log(`[${p.roomUserIndex}] waiting for hello!`);
        })();
      }
      this.peers[i] = p;
    },
    createPeerConnection(p) {
      log.log(`[${p.roomUserIndex}] createPeerConnection`);
      let sp = new Peer({
        initiator: p.initiator,
        trickle: true,
        objectMode: true,
        stream: this.stream,
        offerOptions: {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        }
      });
      p.connection = sp;

      sp.on('error', err => {
        log.error(`[${p.roomUserIndex}] Peer on error:`, err)
        p.error = err;
        this.errorCount += 1;
        if (this.errorCount > 5)
          this.networkIssue = err;
      });

      sp.on('signal', data => {
        log.log(`[${p.roomUserIndex}] signal:`, data)
        p.outgoing = JSON.stringify(data);
        this.updatePeers();
        if (!this.settings.pauseSignal)
          this.sendMessageThroughDatabase(p, this.user.id, p.userId, data);
      });

      sp.on('connect', () => {
        let data = {
          user: {
            name: this.user.name,
            muted: this.muted,
            invisible: this.invisible,
          }
        };
        if (this.streamConstrains && !this.streamConstrains.video)
          data.user.image = this.user.picture0;
        p.messageSent = data;
        this.updatePeers();
        log.log(`[${p.roomUserIndex}] sending connect:`, p.messageSent);
        p.isConnected = true;
        sp.send(JSON.stringify(data));
      });

      sp.on('data', data => {
        data = JSON.parse(data);
        log.log(`[${p.roomUserIndex}] received data:`, data);
        p.messageReceived = data;
        this.updatePeers();
        if (data.user) {
          this.userSetMerge(p.roomUserIndex, Object.assign(data.user, { id: p.userId }));
          this.$emit("userJoined", data.user);
        }
        if (data.extra)
          this.userSetMerge(p.roomUserIndex, data.extra);
        if (data.cmd)
          this.processMessageCommand(data.cmd);
      });

      sp.on("stream", stream => {
        log.log(`[${p.roomUserIndex}] received stream:`, p.peerIndex, stream, getStreamInfo(stream));
        // Camera stream
        if (!p.stream || !p.stream.active) {
          p.stream = stream;
          this.userSetMerge(p.peerIndex, {stream});
          p.stream.oninactive = () => {
            this.userSetMerge(p.peerIndex, {stream:null});
          }
        // Secondary stream
        } else {
          p.screenStream = stream;
          this.userSetMerge(p.peerIndex, {screenStream:stream});
          p.screenStream.oninactive = () => {
            this.userSetMerge(p.peerIndex, {screenStream:null});
          }
        }
      });

      // Close can come for destroy();
      sp.on("close", async () => {
        log.log(`[${p.roomUserIndex}] close`);
        this.userReset(p.roomUserIndex);
        p.isConnected = false;
        if (!this.settings.disableDbClose && !p.receivedClose)
          this.sendMessageThroughDatabase(p, this.user.id, p.userId, { type : "close" });
        if (p.error) {
          log.log(`close after error, waiting ${this.settings.delayReconnectPostError}s before creating new peer.`)
          await sleep(1000 * this.settings.delayReconnectPostError);
        }
        if (this.inRoom && !this.settings.dontCreatePeerAfterClose) {
          this.destroyPeer(p);
          this.createPeer(p.roomUserIndex);
        }
      });

      return p;
    },
    destroyPeerConnection(p) {
      let sp = p.connection;
      p.connection = null;
      sp.removeAllListeners('signal');
      sp.removeAllListeners('connect');
      sp.removeAllListeners('data');
      sp.removeAllListeners('stream');
      sp.removeAllListeners('track');
      sp.removeAllListeners('close');
      sp.removeAllListeners('error');
      sp.destroy();
    },
    destroyPeer(p) {
      log.log(`[${p.roomUserIndex}] destroyPeer`)
      this.userReset(p.roomUserIndex);
      this.peers[p.peerIndex] = null;
      if (p.stream)
        p.stream.getTracks().forEach(track => track.stop());
      p.isBeingDestroyed = true;
      if (p.connection)
        this.destroyPeerConnection(p);
      for (let m of p.messages)
        m.remove();
    },
    async createUserStream(settings) {
      if (this.userConfig.noUserMedia) {
        log.log(`createUserStream noUserMedia`);
        return undefined;
      }
      this.deviceIssue = false;
      log.log(`createUserStream, video=${this.isVideo}`);
      try {
        settings = settings || this.userConfig.settings || {};
        let constrains = {
          video: settings.video !== undefined ? this.isVideo && settings.video : this.isVideo, 
          audio: true,
          definition: this.$debug.lowDef ? 'low' : 'auto',
        };
        log.log("contraints=", constrains);
        // Remove existing stream
        if (this.stream) {
          this.peers.forEach(p => {
            if (p && p.connection)
              p.connection.removeStream(this.stream);
          })
          stopStream(this.stream);
        }
        this.streamConstrains = constrains;
        let stream = await getUserMedia(constrains, settings);
        log.log("streamInfo=", getStreamInfo(stream));
        this.stream = stream;
        if (!stream.getVideoTracks().length) {
          this.userSetMerge(this.userIndex, {image: this.user.picture0});
          this.sendMessageExtra({image: this.user.picture0});
        } else {
          this.userSetMerge(this.userIndex, {image: null});
          this.sendMessageExtra({image: null});
        }
        // Send stream to users
        this.peers.forEach(p => {
          if (p && p.connection)
            p.connection.addStream(stream);
        })
      } catch (error) {
        log.log("getUserMedia error", error); 
        this.deviceIssue = true;
        return;
      }
      if (this.startMuted || this.muted)
        this.setMuted(true);
      this.userSetMerge(this.userIndex, {stream:this.stream});
      return this.stream;
    },
    destroyUserStream() {
      log.log("destroyUserStream");
      // Clear my media
      if (this.stream) {
        stopStream(this.stream);
        this.stream = null;
      }
      if (this.screenStream) {
        stopStream(this.screenStream);
        this.screenStream = null;
      }
    },
    setupCurrentUser() {
      log.log("setupCurrentUser");
      let i = this.userIndex;
      // User
      this.userSetMerge(i, {
        id: this.user.id,
        name: this.user.name,
        roomUserIndex: i,
        local: true,
        muted: this.muted,
        invisible: this.invisible,
        connected: true
      });
      // Fake peer
      this.$set(this.peers, i, {
        roomUserIndex: i,
        userId: this.user.id,
        local: true,
      });
    },
    onRootDBChildAdded(snap) {
      this.receiveMessageThroughDatabase(snap, "child_added");
    },
    async createPeers() {
      if (this.deviceIssue) {
        log.log("Does not create peers until device issue is resolved");
        return;
      }
      log.log("createPeers rootDb=", this.rootDb);
      if (!this.rootDb) {
        this.rootDb = database.ref(`LiveCalls/${this.room.id}`);
        this.rootDb.limitToLast(1).on('child_added', this.onRootDBChildAdded);
      }
      log.log(`Creates ${this.roomUsers.length - 1} peers, userIndex = ${this.userIndex}`);
      for (let i = 0; i < this.roomUsers.length; ++i)
        if (i != this.userIndex)
          this.createPeer(i);
      this.inRoom = true;
      // process existing messages, should only be hello
      this.processAllExistingMessages();
    },
    async destroyPeers() {
      if (!this.inRoom)
        return;
      log.log("destroyPeers()");
      this.inRoom = false;
      // Clean peers
      for (let p of this.peers) {
        if (p && !p.local) {
          this.destroyPeer(p);
        }
      }
    },
    // debug only
    clearSignaling() {
      if (this.rootDb) { 
        this.rootDb.remove();
      }
    },
    // processes command received on the webrtc data channel
    processMessageCommand(cmd) {
      if (cmd.stopScreenStream) {
        this.userSetMerge(cmd.stopScreenStream.userIndex, {screenStream:null});
      }
    },
  }
};
</script>
<style scoped>

.code {
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.localuser {
  transform: rotateY(180deg);
}

</style>
